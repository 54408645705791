import * as React from 'react';
import Logo from '../../assets/images/logo.jpg';

type Props = {

};

export const WartungsModus = (props: Props) => {
    return (
        <div className="wartungsModusContainer">
            <img src={Logo} alt="Logo" className="logo" />
            <div className="content">
                <h1>Wir erstrahlen in neuem Glanz</h1>
                <span>Aktuell modernisieren wir die Webseite für Sie, um Ihnen ein besseres Erlebnis bieten zu können.</span>
            </div>
            <div className="footer">
                <div className="mt-3">
                <span>Varenseller Str. 77a, 33397 Rietberg</span><br/>
                <span>Telefon: +49 (0)5244 / 5969</span><br/>
                    <span>Email: info@franke-lasertronik.de</span>
            </div>
            </div>
        </div>
    );
};
